import { Text } from "@app/design-system";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 8px;
  align-items: center;
  padding-left: 4px;
`;
interface IconWithLabelProps {
  icon: ReactSVGComponent;
  id: string;
  color?: string;
}

export const IconWithLabel = ({
  color,
  icon: Icon,
  id,
}: IconWithLabelProps) => {
  return (
    <>
      <Icon width={24} color={color} />
      <Text size="bodyDefault">{id}</Text>
    </>
  );
};

interface BaseLayerLegendProps {
  icons: Record<string, ReactSVGComponent>;
}

const BaseLayerLegend = ({ icons }: BaseLayerLegendProps) => {
  return (
    <StyledContainer>
      {Object.entries(icons).map(([id, src]) => (
        <IconWithLabel icon={src} id={id} key={id} />
      ))}
    </StyledContainer>
  );
};

export default BaseLayerLegend;
