import type { Feature, Polygon } from "geojson";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import { aerialImageryLayerLabels } from "./constants";

export interface AerialImageryFeatureProperties {
  location: string;
  flight_number: string;
  call_sign: string;
  media_id: string;
  incident_number: number;
  image_tag_date: string; // "2023-10-10T01:35:00Z";
  image_upload_date: string; // "2023-11-23T23:46:07Z";
  image_local_date: string; // "2023-10-10 12:35 (NSW)";
  incident_name: string;
}

export type AerialImageryFeature = Feature<
  Polygon,
  AerialImageryFeatureProperties
>;

export const aerialImageryFeatureLabels = {
  call_sign: "Call sign",
  flight_number: "Flight number",
  image_local_date: "Image local date",
  image_tag_date: "Image tag date",
  image_upload_date: "Image upload date",
  incident_name: "Incident name",
  incident_number: "Incident number",
  location: "Location",
  media_id: "Media ID",
} as const satisfies Record<keyof AerialImageryFeatureProperties, string>;

export interface AerialImageryFeatureServerJson {
  type: "FeatureCollection";
  features: AerialImageryFeature[];
}

export interface AerialImageryLayer {
  boundaryLayers: readonly string[];
  dataLayers: readonly string[];
  label: string;
}

export const aerialImageryLayers = {
  linescan: {
    boundaryLayers: ["aig:ls_boundary"],
    dataLayers: ["aig:ls"],
    label: aerialImageryLayerLabels.linescan,
  },
  lnv: {
    boundaryLayers: ["aig:lnv_boundary"],
    dataLayers: ["aig:lnv"],
    label: aerialImageryLayerLabels.lnv,
  },
  lwir: {
    boundaryLayers: ["aig:lwir_boundary"],
    dataLayers: ["aig:lwir"],
    label: aerialImageryLayerLabels.lwir,
  },
  mwir: {
    boundaryLayers: ["aig:mwir_boundary"],
    dataLayers: ["aig:mwir"],
    label: aerialImageryLayerLabels.mwir,
  },
  mwir_lwir: {
    boundaryLayers: ["aig:mwir_lwir_boundary"],
    dataLayers: ["aig:mwir_lwir"],
    label: aerialImageryLayerLabels.mwir_lwir,
  },
  nir: {
    boundaryLayers: ["aig:nir_boundary"],
    dataLayers: ["aig:nir"],
    label: aerialImageryLayerLabels.nir,
  },
  rgb: {
    boundaryLayers: ["aig:rgb_boundary"],
    dataLayers: ["aig:rgb"],
    label: aerialImageryLayerLabels.rgb,
  },
  // NOTE: The Nova Systems team has advised us that for this layer specifically (RGBN),
  // we need a separate API call for each of the WMS layers (`rgbn` and `rgbn_boundary`)
  // due to a compatibility issue on their side.
  // This is still a known issue as of 2024-07-11 but the Nova Systems team have said
  // they're doing some other aig work and geoserver upgrades that may fix this in a few
  // months time. For now, we load the boundary layers as the main layer as that is
  // where our popup data and other additional metadata is matched.
  rgbn: {
    boundaryLayers: ["aig:rgbn_boundary"],
    dataLayers: [],
    label: aerialImageryLayerLabels.rgbn,
  },
  rgbn_assets: {
    boundaryLayers: [],
    dataLayers: ["aig:rgbn"],
    label: aerialImageryLayerLabels.rgbn,
  },
  swir: {
    boundaryLayers: ["aig:swir_boundary"],
    dataLayers: ["aig:swir"],
    label: aerialImageryLayerLabels.swir,
  },
  tc: {
    boundaryLayers: ["aig:tc_boundary"],
    dataLayers: ["aig:tc"],
    label: aerialImageryLayerLabels.tc,
  },
} as const satisfies Record<string, AerialImageryLayer>;

export type AerialImageryLayerId = keyof typeof aerialImageryLayers;

export const isAerialImageryLayerId = (
  layerId: string,
): layerId is AerialImageryLayerId => {
  return !!aerialImageryLayers[layerId as AerialImageryLayerId];
};

export const getAerialImageryGISUrls = () => {
  // Keep these as separate vars to replace with other maps for testing
  const baseUrl = getMapServerProxyBasepath();

  return {
    dataUrl: `${baseUrl}/geoserver/aig/wfs`,
    tileUrl: `${baseUrl}/geoserver/aig/wms`,
  };
};
