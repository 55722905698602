import { FIRE_WEATHER_URL } from "../../../config/general";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";

export const FIRE_CONDITIONS_LAYERS = {
  continuousHaines: "IDZ71115_AUS_CHaines_SFC",
  windChangeDangerIndex: "IDZ10146_AUS_AFDRS_wcdi_SFC",
  windChangeDangerFlag: "IDZ10147_AUS_AFDRS_wcdi_flag_SFC",
  temperature: "IDZ71000_AUS_T_SFC",
  mixingHeight: "IDZ71109_AUS_MixHgt_SFC",
  twentyFivePercentChancePrecipitation: "IDZ71014_AUS_DailyPrecip25Pct_SFC",
  seventyFivePercentChancePrecipitation: "IDZ71016_AUS_DailyPrecip75Pct_SFC",
  relativeHumidity: "IDZ71018_AUS_RH_SFC",
  windDirection: "IDZ71089_AUS_Wind_Dir_SFC",
  windSpeedKmH: "IDZ71071_AUS_WindMagKmh_SFC",
  windGustKmH: "IDZ71072_AUS_WindGustKmh_SFC",
  windDirection1500MElevation: "IDZ71111_AUS_Wind_Dir_1500mAMSL",
  windSpeed1500MElevationKmH: "IDZ71110_AUS_WindMagKmh_1500mAMSL",
  fireDangerRating: "IDZ10134_AUS_AFDRS_fdr_SFC",
};
const TILE_SIZE = 516;

const getFireConditionsUrlMap = () => {
  const fireConditionsMap = new Map();
  Object.entries(FIRE_CONDITIONS_LAYERS).forEach(([key, layerName]) => {
    const query = buildMapboxBBoxQuery({
      service: "WMS",
      version: "1.3.0",
      request: "GetMap",
      format: "image/png",
      width: TILE_SIZE.toString(),
      height: TILE_SIZE.toString(),
      layers: layerName,
      transparent: "true",
      crs: "EPSG:3857",
    });
    fireConditionsMap.set(key, [`${FIRE_WEATHER_URL}?${query}`]);
  });
  return fireConditionsMap;
};

export default getFireConditionsUrlMap();
