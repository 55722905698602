import {
  AerialIgnitionPlannedFieldObs,
  AerialIgnitionFieldObs,
  Annotation,
  BackburnLineBurningFieldObs,
  BackburnLineCompletedFieldObs,
  BackburnLineProposedFieldObs,
  ControlLineCompletedFieldObs,
  ControlLineProposedFieldObs,
  EmergencyAlertAreaFieldObs,
  FireBurntAreaFieldObs,
  FireEdgeInactiveFieldObs,
  FireEdgePredictedFieldObs,
  FirePreviouslyBurntAreaFieldObs,
  FireProposedBurnAreaFieldObs,
  SafetyLabel,
  SectorName,
  StaticIconWrapper,
  Text,
  FireEdgeContainedFieldObs,
  FireEdgeGoingFieldObs,
  MachineCutTrack,
  Track,
  SymbolHelipadFieldObs,
  SymbolDivisionalCommandPointFieldObs,
  SymbolEscapeRouteFieldObs,
  SymbolMedicalFieldObs,
  SymbolEvacuationCentreFieldObs,
  PlantFieldObs,
  SymbolPropertyDamagedFieldObs,
  SymbolRaftCrewFieldObs,
  SymbolRefugeAreaFieldObs,
  SymbolSectorCommandPointFieldObs,
  SectorBoundaryFieldObs,
  SymbolSmokerOnGroundFieldObs,
  SymbolStagingAreaFieldObs,
  SymbolTreeDownFieldObs,
  SymbolWaterPointFieldObs,
  SymbolWaterPointHelicopterFieldObs,
  SymbolWaterPointVehicleFieldObs,
  FireOrigin,
  SpotFire,
  Hotspot,
  FireDirectionFieldObs,
  WindDirectionFieldObs,
  AssetThreatenedFieldObs,
  AssetEmptyFieldObs,
  AssetFloraFieldObs,
  AssetFaunaFieldObs,
  AssetIndigenousSiteFieldObs,
  AssetHistoricSiteFieldObs,
  SymbolAirbaseFieldObs,
  SymbolAssemblyAreaFieldObs,
  SymbolBaseCampFieldObs,
  SymbolControlCentreFieldObs,
  SymbolControlPointFieldObs,
  DivisionalBoundaryFieldObs,
} from "@app/design-system";
import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: grid;
  gap: 16px;
`;

const StyledBody = styled.div`
  display: grid;
  gap: 8px;
`;

const StyledItemGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 8px;
`;

interface FieldObsIcon {
  icon: ReactSVGComponent;
  category: string;
}

const iconFieldObsLegends: FieldObsIcon[] = [
  {
    icon: FireOrigin,
    category: "Fire Origin",
  },
  {
    icon: SpotFire,
    category: "Spot Fire",
  },
  {
    icon: Hotspot,
    category: "Hotspot",
  },
  {
    icon: FireDirectionFieldObs,
    category: "Fire Direction",
  },
  {
    icon: WindDirectionFieldObs,
    category: "Wind Direction",
  },
  {
    icon: AssetEmptyFieldObs,
    category: "Asset",
  },
  {
    icon: AssetThreatenedFieldObs,
    category: "Threatened Asset",
  },
  {
    icon: AssetHistoricSiteFieldObs,
    category: "Historic Site",
  },
  {
    icon: AssetIndigenousSiteFieldObs,
    category: "Indigenous Site",
  },
  {
    icon: AssetFaunaFieldObs,
    category: "Threatened Fauna",
  },
  {
    icon: AssetFloraFieldObs,
    category: "Threatened Flora",
  },
  {
    icon: SymbolAirbaseFieldObs,
    category: "Air Base",
  },
  {
    icon: SymbolAssemblyAreaFieldObs,
    category: "Assembly Area",
  },
  {
    icon: SymbolBaseCampFieldObs,
    category: "Base Camp",
  },
  {
    icon: SymbolControlCentreFieldObs,
    category: "Control Centre",
  },
  {
    icon: SymbolControlPointFieldObs,
    category: "Control Point",
  },
  {
    icon: DivisionalBoundaryFieldObs,
    category: "Divisional Boundary",
  },
];

const iconFieldObsLinesLegends: FieldObsIcon[] = [
  {
    icon: AerialIgnitionFieldObs,
    category: "Aerial Ignition Complete",
  },
  {
    icon: AerialIgnitionPlannedFieldObs,
    category: "Aerial ignition Planned",
  },
  {
    icon: BackburnLineBurningFieldObs,
    category: "Backburn Line Burning",
  },
  {
    icon: BackburnLineCompletedFieldObs,
    category: "Backburn Line Completed",
  },
  {
    icon: BackburnLineProposedFieldObs,
    category: "Backburn Line Proposed",
  },
  {
    icon: ControlLineCompletedFieldObs,
    category: "Control Line Completed",
  },
  {
    icon: ControlLineProposedFieldObs,
    category: "Control Line Proposed",
  },
  {
    icon: FireEdgeContainedFieldObs,
    category: "Fire Edge Contained",
  },
  {
    icon: FireEdgeGoingFieldObs,
    category: "Fire Edge Going",
  },
  {
    icon: FireEdgeInactiveFieldObs,
    category: "Fire Edge Inactive",
  },
  {
    icon: FireEdgePredictedFieldObs,
    category: "Fire Edge Predicted",
  },
  {
    icon: MachineCutTrack,
    category: "Machine Cut Track",
  },
  {
    icon: Track,
    category: "Track",
  },
  {
    icon: SymbolDivisionalCommandPointFieldObs,
    category: "Divisional Command",
  },
  {
    icon: SymbolEscapeRouteFieldObs,
    category: "Escape Route",
  },
  {
    icon: SymbolEvacuationCentreFieldObs,
    category: "Evacuation Centre",
  },
  {
    icon: SymbolHelipadFieldObs,
    category: "Helipad Confirmed",
  },
  {
    icon: SymbolMedicalFieldObs,
    category: "Medical",
  },
  {
    icon: PlantFieldObs,
    category: "Plant",
  },
  {
    icon: SymbolPropertyDamagedFieldObs,
    category: "Property Damage",
  },
  {
    icon: SymbolRaftCrewFieldObs,
    category: "RAFT Crews",
  },
  {
    icon: SymbolRefugeAreaFieldObs,
    category: "Refuge Area",
  },
  {
    icon: SectorBoundaryFieldObs,
    category: "Sector Boundary",
  },
  {
    icon: SymbolSectorCommandPointFieldObs,
    category: "Sector Command",
  },
  {
    icon: SymbolSmokerOnGroundFieldObs,
    category: "Smoker on Ground",
  },
  {
    icon: SymbolStagingAreaFieldObs,
    category: "Staging Area",
  },
  {
    icon: SymbolTreeDownFieldObs,
    category: "Tree Down",
  },
  {
    icon: SymbolWaterPointFieldObs,
    category: "Water Point",
  },
  {
    icon: SymbolWaterPointHelicopterFieldObs,
    category: "Water Point Helicopter",
  },
  {
    icon: SymbolWaterPointVehicleFieldObs,
    category: "Water Point Vehicle",
  },
];

const iconFieldObsPolygonsLegends: FieldObsIcon[] = [
  {
    icon: FireBurntAreaFieldObs,
    category: "Burnt Area",
  },
  {
    icon: EmergencyAlertAreaFieldObs,
    category: "Emergency Alert",
  },
  {
    icon: FirePreviouslyBurntAreaFieldObs,
    category: "Previous Burnt Area Alert",
  },
  {
    icon: FireProposedBurnAreaFieldObs,
    category: "Proposed Burn Area",
  },
];

const iconFieldObsLabelsLegends: FieldObsIcon[] = [
  {
    icon: Annotation,
    category: "Annotation",
  },
  {
    icon: Annotation,
    category: "Division Name",
  },
  {
    icon: SafetyLabel,
    category: "Safety",
  },
  {
    icon: SectorName,
    category: "Sector Name",
  },
];

const FieldObsIconLegend = () => {
  return (
    <StyledContainer>
      <StyledBody>
        <StyledItemGrid>
          {iconFieldObsLegends.map(({ icon, category }) => (
            <React.Fragment key={category}>
              <StaticIconWrapper icon={icon} size="sm" />
              <Text size="bodyDefault">{category}</Text>
            </React.Fragment>
          ))}
        </StyledItemGrid>
      </StyledBody>
      <StyledBody>
        <Text size="subtitleMd">Lines</Text>
        <StyledItemGrid>
          {iconFieldObsLinesLegends.map(({ icon, category }) => (
            <React.Fragment key={category}>
              <StaticIconWrapper icon={icon} size="sm" />
              <Text size="bodyDefault">{category}</Text>
            </React.Fragment>
          ))}
        </StyledItemGrid>
      </StyledBody>
      <StyledBody>
        <Text size="subtitleMd">Polygons</Text>
        <StyledItemGrid>
          {iconFieldObsPolygonsLegends.map(({ icon, category }) => (
            <React.Fragment key={category}>
              <StaticIconWrapper icon={icon} size="sm" />
              <Text size="bodyDefault">{category}</Text>
            </React.Fragment>
          ))}
        </StyledItemGrid>
      </StyledBody>
      <StyledBody>
        <Text size="subtitleMd">Labels</Text>
        <StyledItemGrid>
          {iconFieldObsLabelsLegends.map(({ icon, category }) => (
            <React.Fragment key={category}>
              <StaticIconWrapper icon={icon} size="sm" />
              <Text size="bodyDefault">{category}</Text>
            </React.Fragment>
          ))}
        </StyledItemGrid>
      </StyledBody>
    </StyledContainer>
  );
};

export default FieldObsIconLegend;
