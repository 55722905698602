const MAPBOX_BBOX_PLACEHOLDER = "{bbox-epsg-3857}";

/**
 * The BBOX query parameter is intercepted by Mapbox and used to insert the
 * correct bounding box coordinates. Because of this, it must not be URL encoded,
 * or the Mapbox search for the placeholder won't recognise URL encoded curly
 * braces. So while we can use URLSearchParams above to encode the rest of the
 * query, we must interpolate this part ourselves
 *
 */
const buildMapboxBBoxQuery = (query: Record<string, string>) => {
  const queryString = new URLSearchParams(query).toString();

  return `${queryString}&BBOX=${MAPBOX_BBOX_PLACEHOLDER}`;
};

export default buildMapboxBBoxQuery;
