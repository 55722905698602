import { useQuery } from "@tanstack/react-query";
import { REFETCH_INTERVAL_FREQUENT } from "../../../config/refetch";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import {
  getAerialImageryGISUrls,
  type AerialImageryFeatureServerJson,
  type AerialImageryLayer,
} from "./utils";

interface GetAerialImageryMetaResponse {
  features: AerialImageryFeatureServerJson["features"];
  lastUpdated: string | null;
  raw: AerialImageryFeatureServerJson;
}

interface GetAerialImageryMetaParams {
  accessToken: string | undefined;
  aerialImageryLayer: AerialImageryLayer;
  signal: AbortSignal;
}

const getAerialImageryMeta = ({
  accessToken,
  aerialImageryLayer,
  signal,
}: GetAerialImageryMetaParams) => {
  const { dataUrl } = getAerialImageryGISUrls();

  const queryString = new URLSearchParams({
    service: "WFS",
    version: "2.0.0",
    request: "GetFeature",
    typeNames: aerialImageryLayer.boundaryLayers.join(","),
    outputFormat: "json",
  }).toString();

  return fetch(`${dataUrl}?${queryString}`, {
    headers: dataUrl.includes("shared-athena")
      ? { Authorization: `Bearer ${accessToken}` }
      : undefined,
    signal,
  })
    .then((response) => {
      signal.throwIfAborted();
      return response.json() as Promise<AerialImageryFeatureServerJson>;
    })
    .then((data): GetAerialImageryMetaResponse => {
      return {
        features: data.features,
        lastUpdated: data.features.length
          ? data.features.reduce((acc, feature) => {
              // Check the tag date (with a backup of the upload date) and find the most recent update date for the layer
              const date = feature.properties.image_tag_date;

              return date && new Date(date) > new Date(acc) ? date : acc;
            }, "0")
          : null,
        raw: data,
      };
    })
    .catch(catchAbortError);
};

interface UseGetAerialImageryMetaParams {
  enabled?: boolean;
  aerialImageryLayer: AerialImageryLayer;
}

export const useGetAerialImageryMeta = ({
  enabled,
  aerialImageryLayer,
}: UseGetAerialImageryMetaParams) => {
  const accessToken = useAuthAccessToken();
  const query = useQuery({
    enabled:
      !!accessToken && !!aerialImageryLayer.boundaryLayers.length && enabled,
    queryKey: [
      "getAerialImageryLastUpdated",
      ...aerialImageryLayer.boundaryLayers,
    ],
    queryFn: ({ signal }) => {
      return getAerialImageryMeta({
        accessToken,
        aerialImageryLayer,
        signal,
      });
    },
    refetchInterval: REFETCH_INTERVAL_FREQUENT,
  });

  return query;
};
