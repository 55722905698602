import { Text, media, showToast } from "@app/design-system";
import * as Sentry from "@sentry/nextjs";
import noop from "lodash/noop";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";

const StyledLightningLegend = styled.div`
  display: grid;
  gap: 0.25rem;
`;

const StyledCategoryContainer = styled.div`
  display: flex;
`;

const StyledCategoryGrid = styled.div`
  flex: 1;
  display: grid;
  gap: 0.25rem;
`;

const StyledCategory = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  img {
    height: 2rem;
    width: 2rem;

    @media ${media.lg} {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
`;

interface LightningLegend {
  label: string;
  contentType: string;
  imageData: string;
}

interface FetchLegendParams {
  accessToken: string;
}

export const LEGEND_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Lightning/MapServer/legend`;

export const fetchLegend = async ({ accessToken }: FetchLegendParams) => {
  const response = await fetch(`${LEGEND_URL}?f=pjson`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to retrieve lightning strike legend data");
  }

  const json = await response.json();

  return {
    positiveStrikesLegend: json.layers[0].legend,
    negativeStrikesLegend: json.layers[1].legend,
  };
};

const LightningLegend = () => {
  const authAccessToken = useAuthAccessToken();

  const [positiveStrikes, setPositiveStrikes] = useState<LightningLegend[]>([]);
  const [negativeStrikes, setNegativeStrikes] = useState<LightningLegend[]>([]);

  useEffect(() => {
    if (!authAccessToken) return noop;

    const controller = new AbortController();
    const loadIcons = async () => {
      try {
        const { positiveStrikesLegend, negativeStrikesLegend } =
          await fetchLegend({
            accessToken: authAccessToken,
          });

        if (controller.signal.aborted) return;

        setPositiveStrikes(positiveStrikesLegend);
        setNegativeStrikes(negativeStrikesLegend);
      } catch (error) {
        if (controller.signal.aborted) return;

        Sentry.captureException(error);
        showToast({
          message: "Please reload layer and try again",
          title: "Unable to load Lightning legend",
          variant: "error",
        });
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadIcons();

    return () => controller.abort();
  }, [authAccessToken]);

  return (
    <StyledLightningLegend>
      <Text size="bodyDefault">
        Lightning strikes (excludes cloud to cloud)
      </Text>
      <StyledCategoryContainer>
        <StyledCategoryGrid>
          <Text size="label" variant="weak">
            Positive strikes
          </Text>
          {positiveStrikes.map(({ label, contentType, imageData }) => (
            <StyledCategory key={label}>
              <img
                src={`data:${contentType};base64,${imageData}`}
                alt={label}
              />
              <Text size="footnote">{label}</Text>
            </StyledCategory>
          ))}
        </StyledCategoryGrid>
        <StyledCategoryGrid>
          <Text size="label" variant="weak">
            Negative strikes
          </Text>
          {negativeStrikes.map(({ label, contentType, imageData }) => (
            <StyledCategory key={label}>
              <img
                src={`data:${contentType};base64,${imageData}`}
                alt={label}
              />
              <Text size="footnote">{label}</Text>
            </StyledCategory>
          ))}
        </StyledCategoryGrid>
      </StyledCategoryContainer>
    </StyledLightningLegend>
  );
};

export default LightningLegend;
