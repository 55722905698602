export const aerialImageryLayerLabels = {
  linescan: "Linescan",
  lnv: "LNV (LWIR, NIR, Visible)",
  lwir: "Longwave infrared (LWIR)",
  mwir: "Mediumwave infrared (MWIR)",
  mwir_lwir: "Medium & longwave infrared (MWIR_LWIR)",
  nir: "Near infrared",
  rgb: "Visible (RGB)",
  rgbn: "Visible & NIR (RGBN)",
  swir: "Shortwave infrared",
  tc: "Thermal composite",
} as const satisfies Record<string, string>;
