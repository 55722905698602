import ColorRangeLegend, {
  type ColorRange,
  type ColorRangeCollection,
} from "../ColorRangeLegend/ColorRangeLegend";

const timeSincePostRanges: ColorRange[] = [
  { color: "#dc015d", range: [0, 2] },
  { color: "#f36ea7", range: [2, 6] },
  { color: "#f895bf", range: [6, 12] },
  { color: "#fdbad5", range: [12, 24] },
  { color: "#ffdae8", range: [24, 48] },
];

const ranges: ColorRangeCollection[] = [
  { label: "Time since posted (hours)", ranges: timeSincePostRanges },
];

const SocialDotLegend = () => {
  return <ColorRangeLegend ranges={ranges} />;
};

export default SocialDotLegend;
