import type { SubLayerConfig } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import type { MapImage } from "../utils/loadImage";

export const fireMapperLayerNames = [
  "Labels",
  "Points",
  "Photos",
  "PDFs",
  "Lines",
  "Areas",
] as const;

export const fireMapperGISLayers = {
  "1Hr": { Labels: 1, Points: 2, Photos: 3, PDFs: 4, Lines: 5, Areas: 6 },
  "3Hrs": { Labels: 8, Points: 9, Photos: 10, PDFs: 11, Lines: 12, Areas: 13 },
  "24Hrs": {
    Labels: 15,
    Points: 16,
    Photos: 17,
    PDFs: 18,
    Lines: 19,
    Areas: 20,
  },
} as const satisfies Record<
  string,
  Record<(typeof fireMapperLayerNames)[number], number>
>;

export interface FireMapperFeatureServerIconJson {
  drawingInfo: {
    renderer: {
      uniqueValueInfos: {
        type: "esriPMS";
        symbol: { contentType: string; imageData: string };
        label: string;
        value: string;
      }[];
    };
  };
}

type LineColorArray = [number, number, number, number];

type SymbolLines = {
  type: "esriSLS";
  style: "esriSLSDash" | "esriSLSDashDot" | "esriSLSSolid";
  color: LineColorArray;
  width: number;
};

type SymbolAreas = {
  type: "esriSFS";
  style:
    | "esriSFSBackwardDiagonal"
    | "esriSFSForwardDiagonal"
    | "esriSFSHorizontal"
    | "esriSFSVertical";
  color: LineColorArray;
  outline: {
    type: "esriSLS";
    style: "esriSLSDash" | "esriSLSNull" | "esriSLSSolid";
    color: LineColorArray;
    width: number;
  };
};

export interface FireMapperFeatureServerLineJson {
  drawingInfo: {
    renderer: {
      uniqueValueInfos: {
        symbol: SymbolLines | SymbolAreas;
        label: string;
        value: string;
      }[];
    };
  };
}

export interface FireMapperLayerProps {
  layer: (typeof fireMapperLayerNames)[number];
  layerType: keyof typeof fireMapperGISLayers;
  dataKey: string;
}

export const getFireMapperSubLayer = (
  layer: FireMapperLayerProps["layer"],
  layerType: FireMapperLayerProps["layerType"],
) =>
  ({
    id: `fireMapper${layer}${layerType}Option`,
    label: layer,
  }) as const satisfies SubLayerConfig;

// Colors come in as [r, g, b, a], so turn it into hex with alpha
export const getLineColorAsHex = (color: LineColorArray) => ({
  hex: `#${color
    .slice(0, 3)
    .map((value) => value.toString(16).padStart(2, "0"))
    .join("")}`,
  opacity: color[3] / 255,
});

export const getFireMapperMapIcons = (
  featureServerJson: FireMapperFeatureServerIconJson,
): MapImage[] =>
  featureServerJson.drawingInfo.renderer.uniqueValueInfos.map((info) => ({
    src: `data:${info.symbol.contentType};base64,${info.symbol.imageData}`,
    imageId: info.label,
  }));

export const getFireMapperMapLines = (
  featureServerJson: FireMapperFeatureServerLineJson,
) =>
  featureServerJson.drawingInfo.renderer.uniqueValueInfos.map((info) => info);

export const getFireMapperGISUrls = (
  layer: FireMapperLayerProps["layer"],
  layerType: FireMapperLayerProps["layerType"],
) => {
  // Keep these as separate vars to replace with other maps for testing
  const baseUrl = getMapServerProxyBasepath();
  const mapName = "FireMapperFiltered";

  return {
    mapUrl: `${baseUrl}/arcgis/rest/services/Operations/${mapName}/MapServer/${fireMapperGISLayers[layerType][layer]}/query`,
    symbologyUrl: `${baseUrl}/arcgis/rest/services/Operations/${mapName}/MapServer/${fireMapperGISLayers[layerType][layer]}?f=pjson`,
    tileUrl: `${baseUrl}/arcgis/rest/services/Operations/${mapName}/MapServer/export`,
  };
};
