import { useContext } from "react";
import { MapContext, type MapContextValue } from "./MapProvider";

/**
 * Hook to get the Map instance.
 * @param requireLib Specify if this hook should only be rendered when using a particular Map library
 * @returns The Map instance
 */
export default function useMapContext<
  TLib extends MapContextValue["lib"] = MapContextValue["lib"],
>(
  requireLib?: TLib,
): NonNullable<Extract<MapContextValue, { lib: TLib }>["map"]> {
  const value = useContext(MapContext);
  if (!value) {
    throw new Error("useMapContext must be used within a MapProvider");
  }

  if (!value.map) {
    throw new Error("useMapContext must be used within a Map child component");
  }

  if (requireLib && value.lib !== requireLib) {
    throw new Error(
      `useMapContext was invoked with requireLib="${requireLib}", but was rendered inside a MapProvider with lib="${value.lib}"`,
    );
  }

  // @ts-expect-error TS doesn't quite understand what's going on here
  return value.map;
}
