import {
  Aeroplane,
  AircraftAvailable,
  AircraftDeployed,
  AircraftNotAvailable,
  AircraftNotKnown,
  AircraftPlannedDispatch,
  Field,
  FieldGroup,
  Helicopter,
  StaticIconWrapper,
  Text,
} from "@app/design-system";
import capitalize from "lodash/capitalize";
import React from "react";
import styled from "styled-components";

const StyledAircraftPositionsGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.5rem;
`;

type AircraftStatus =
  | "available"
  | "limited"
  | "deployed"
  | "planned-dispatch"
  | "standby"
  | "unavailable"
  | "unserviceable"
  | "not-known";

export const AIRCRAFT_STATUS: Record<AircraftStatus, ReactSVGComponent> = {
  available: AircraftAvailable,
  limited: AircraftAvailable,
  deployed: AircraftDeployed,
  "planned-dispatch": AircraftPlannedDispatch,
  standby: AircraftPlannedDispatch,
  unavailable: AircraftNotAvailable,
  unserviceable: AircraftNotAvailable,
  "not-known": AircraftNotKnown,
};

const formatStatus = (status: string) => {
  return capitalize(status.replaceAll("-", " "));
};

const AircraftPositionsLegend = () => {
  return (
    <FieldGroup>
      <Field label="Category">
        <StyledAircraftPositionsGrid>
          <StaticIconWrapper icon={Aeroplane} size="sm" />
          <Text size="bodyDefault">Aeroplane</Text>
          <StaticIconWrapper icon={Helicopter} size="sm" />
          <Text size="bodyDefault">Helicopter</Text>
        </StyledAircraftPositionsGrid>
      </Field>
      <Field label="Status">
        <StyledAircraftPositionsGrid>
          {Object.keys(AIRCRAFT_STATUS).map((key) => (
            <React.Fragment key={key}>
              <StaticIconWrapper
                icon={AIRCRAFT_STATUS[key as AircraftStatus]}
                size="sm"
              />
              <Text size="bodyDefault">{formatStatus(key)}</Text>
            </React.Fragment>
          ))}
        </StyledAircraftPositionsGrid>
      </Field>
    </FieldGroup>
  );
};

export default AircraftPositionsLegend;
