import {
  FlightRiskLow,
  FlightRiskModerate,
  FlightRiskSevere,
  Text,
} from "@app/design-system";
import React from "react";
import styled from "styled-components";

const StyledFireGroundFlightRiskLegend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCategory = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const FIRE_FLIGHT_RISK = [
  { Icon: FlightRiskLow, label: "Low flight risk" },
  { Icon: FlightRiskModerate, label: "Moderate flight risk" },
  { Icon: FlightRiskSevere, label: "Severe flight risk" },
];

const FireGroundFlightRiskLegend = () => {
  return (
    <StyledFireGroundFlightRiskLegend>
      {FIRE_FLIGHT_RISK.map(({ label, Icon }) => (
        <StyledCategory key={label}>
          <Icon width={20} />
          <Text size="bodyDefault">{label}</Text>
        </StyledCategory>
      ))}
    </StyledFireGroundFlightRiskLegend>
  );
};

export default FireGroundFlightRiskLegend;
