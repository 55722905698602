import {
  ASACTAmbulanceService,
  AVLCategoryBand2,
  AVLCategoryBand3,
  AccommodationFacilitiesCampingGrounds,
  AccommodationFacilitiesCaravanPark,
  AccommodationFacilitiesClub,
  AccommodationFacilitiesHotel,
  AccommodationFacilitiesUnknown,
  AerialContainment,
  AerialIgnitionPlanned,
  AgedCareFacilityAgedCareAndResidents,
  AgedCareFacilityNursingHome,
  AgedCareFacilityUnknown,
  AlertLevelDefault,
  AssetEmpty,
  BackburnLineBurning,
  BackburnLineCompleted,
  BackburnLineProposed,
  BlackspotsIneligible,
  BlackspotsReported,
  BlackspotsUnknown,
  BurntAreaHazardReduction,
  CommunicationTowerABC,
  CommunicationTowerPMR,
  CommunicationTowerRFS,
  CommunicationTowerUnknown,
  ControlLineCompleted,
  ControlLineProposed,
  DivisionalBoundary,
  EducationChildCareCentre,
  EducationCollege,
  EducationKindergarten,
  EducationPrimary,
  EducationSecondary,
  EducationTafe,
  EducationUniversity,
  EducationUnknown,
  EmergencyAlert,
  EmergencyAlertArea,
  FaRSACTFireAndRescue,
  FaftDashed,
  FaftDotted,
  FaftSolid,
  FaftStrategic,
  FaftTactical,
  FaftTrack,
  FieldObsSitRep,
  FieldObsWeather,
  FireBurntArea,
  FireDirection,
  FireEdgeGoing,
  FireEdgeInactive,
  FireEdgePredicted,
  FireExclusionArea,
  FireHotSpotUnconfirmed,
  FireIncidentConfirmed,
  FireIncidentUnconfirmed,
  FireOriginUnconfirmed,
  FirePreviouslyBurntArea,
  FireProposedBurnArea,
  FloodIncidentConfirmed,
  FloodIncidentUnconfirmed,
  FloodedAreaConfirmed,
  FloodedAreaPredicted,
  HealthFacilityDisabilitySupportService,
  HealthFacilityHospitalGeneral,
  HealthFacilityHospitalPrivate,
  HealthFacilityIndigenousHealthCentre,
  HealthFacilityUnknown,
  LiveTrafficAccident,
  LiveTrafficCongestion,
  LiveTrafficConstruction,
  LiveTrafficDisabledVehicle,
  LiveTrafficLaneRestriction,
  LiveTrafficMassTransit,
  LiveTrafficOtherNews,
  LiveTrafficPlannedEvent,
  LiveTrafficRoadClosure,
  LiveTrafficRoadHazard,
  LiveTrafficUnknown,
  LiveTrafficWeatherEvent,
  NeighbourhoodSaferPlaces,
  OtherIncidentArea,
  OtherIncidentConfirmed,
  OtherIncidentUnconfirmed,
  PipelineGas,
  PipelineOil,
  PotentialEmberAttack,
  PowerTransmissionLines,
  PowerTransmissionStations,
  PredictedFireEdge,
  RFSACTRuralFireService,
  RedMapToday,
  RedMapTomorrow,
  RtaIncident,
  SESACTStateEmergencyService,
  SectorBoundary,
  SiteInformation,
  SpotFireConfirmed,
  SpotFireUnconfirmed,
  StateForestPlantation,
  SymbolAirbase,
  SymbolAssemblyArea,
  SymbolBaseCamp,
  SymbolControlCentre,
  SymbolControlPoint,
  SymbolDivisionalCommandPoint,
  SymbolEvacuationCentre,
  SymbolHelipad,
  SymbolMedical,
  SymbolPropertyDamaged,
  SymbolRaftCrew,
  SymbolRefugeArea,
  SymbolSectorCommandPoint,
  SymbolSmokerStanding,
  SymbolStagingArea,
  SymbolTreeDown,
  SymbolWaterPoint,
  SymbolWaterPointHelicopter,
  SymbolWaterPointVehicle,
  TelephoneExchange,
  TotalFireBanAreasToday,
  TotalFireBanAreasTomorrow,
  VehicleIncidentConfirmed,
  VehicleIncidentUnconfirmed,
  WindDirection,
} from "@app/design-system";
import {
  HABITABLE_PROPERTIES_BASE,
  HABITABLE_PROPERTIES_SWIMMING_POOLS,
} from "../HabitableProperties/constants";

export const ACT_ESA_RESOURCES_LEGEND = {
  "AS - ACT Ambulance Service": ASACTAmbulanceService,
  "FaRS - ACT Fire and Rescue": FaRSACTFireAndRescue,
  "RFS - ACT Rural Fire Service": RFSACTRuralFireService,
  "SES - ACT State Emergency Service": SESACTStateEmergencyService,
};

export const ACCOMMODATION_FACILITIES_LEGEND = {
  "Camping Ground": AccommodationFacilitiesCampingGrounds,
  "Caravan Park": AccommodationFacilitiesCaravanPark,
  Club: AccommodationFacilitiesClub,
  Hotel: AccommodationFacilitiesHotel,
  Unknown: AccommodationFacilitiesUnknown,
};

export const AGED_CARE_LEGEND = {
  "Aged Care & Residence": AgedCareFacilityAgedCareAndResidents,
  "Nursing Home": AgedCareFacilityNursingHome,
  Unknown: AgedCareFacilityUnknown,
};

export const AIRPORTS_AND_HELIPADS_LEGEND = {
  Airport: AVLCategoryBand2,
  Helipad: AVLCategoryBand3,
};

export const BRIGADES_COVERAGE_LEGEND = {
  Severe: "#EA1D2C",
  Moderate: "#F58C1D",
  "No impact": "#0BA350",
  Assessed: "#5d5d5d",
  "No data": "transparent",
};

export const BURNT_AREA_CURRENT_FIRE_SEASON_LEGEND = {
  "Burnt Area – current fire season": FirePreviouslyBurntArea,
};

export const BURNT_AREA_HAZARD_REDUCTION_LEGEND = {
  "Burnt Area – hazard reduction": BurntAreaHazardReduction,
};

export const COMMUNICATION_TOWERS_LEGEND = {
  "RFS Paging Tower": CommunicationTowerRFS,
  "PMR Tower": CommunicationTowerPMR,
  "ABC Radio Tower": CommunicationTowerABC,
  Unknown: CommunicationTowerUnknown,
};

export const EDUCATION_FACILITIES_LEGEND = {
  "Child Care": EducationChildCareCentre,
  Kindergarten: EducationKindergarten,
  Primary: EducationPrimary,
  Secondary: EducationSecondary,
  College: EducationCollege,
  TAFE: EducationTafe,
  University: EducationUniversity,
  Unknown: EducationUnknown,
};

export const EMERGENCY_ALERTS_LEGEND = {
  "Emergency Alert Area": EmergencyAlertArea,
};

export const FIELD_OBS_SIT_REP_UNDER_1_HR_LEGEND = {
  "Under 1 Hrs": FieldObsSitRep,
};

export const FIELD_OBS_SIT_REP_UNDER_3_HRS_LEGEND = {
  "Under 3 Hrs": FieldObsSitRep,
};

export const FIELD_OBS_WEATHER_UNDER_1_HR_LEGEND = {
  "Under 1 Hr": FieldObsWeather,
};

export const FIELD_OBS_WEATHER_UNDER_3_HRS_LEGEND = {
  "Under 3 Hrs": FieldObsWeather,
};

export const FIRE_FEATURES_LEGEND = {
  "Fire Incident Confirmed": FireIncidentConfirmed,
  "Fire Incident Unconfirmed": FireIncidentUnconfirmed,
  "Flood Incident Confirmed": FloodIncidentConfirmed,
  "Flood Incident Unconfirmed": FloodIncidentUnconfirmed,
  "Vehicle Incident Confirmed": VehicleIncidentConfirmed,
  "Vehicle Incident Unconfirmed": VehicleIncidentUnconfirmed,
  "Other Incident Confirmed": OtherIncidentConfirmed,
  "Other Incident Unconfirmed": OtherIncidentUnconfirmed,
  "Fire Direction": FireDirection,
  "Spot Fire Confirmed": SpotFireConfirmed,
  "Spot Fire Unconfirmed": SpotFireUnconfirmed,
  "Wind Direction": WindDirection,
  "Fire Origin Unconfirmed": FireOriginUnconfirmed,
  Asset: AssetEmpty,
  "Air base Confirmed": SymbolAirbase,
  "Assembly Area Confirmed": SymbolAssemblyArea,
  "Base Camp Confirmed": SymbolBaseCamp,
  "Control Centre Confirmed": SymbolControlCentre,
  "Control Point Confirmed": SymbolControlPoint,
  "Divisional Boundary": DivisionalBoundary,
  "Divisional Command": SymbolDivisionalCommandPoint,
  "Evacuation Centre Confirmed": SymbolEvacuationCentre,
  "Helipad Confirmed": SymbolHelipad,
  Medical: SymbolMedical,
  "Property Damaged Confirmed": SymbolPropertyDamaged,
  "RAFT Crews": SymbolRaftCrew,
  "Refuge Area Confirmed": SymbolRefugeArea,
  "Sector Boundary": SectorBoundary,
  "Sector Command": SymbolSectorCommandPoint,
  "Smoker Standing": SymbolSmokerStanding,
  "Staging Area Confirmed": SymbolStagingArea,
  "Tree Down Confirmed": SymbolTreeDown,
  "Water Point": SymbolWaterPoint,
  "Water Point Helicopter": SymbolWaterPointHelicopter,
  "Water Point Vehicle": SymbolWaterPointVehicle,
  "Backburn Line Burning": BackburnLineBurning,
  "Aerial Containment": AerialContainment,
  "Aerial Ignition Planned": AerialIgnitionPlanned,
  "Control Line Completed": ControlLineCompleted,
  "Control Line Proposed": ControlLineProposed,
  "Backburn Line Completed": BackburnLineCompleted,
  "Backburn Line Proposed": BackburnLineProposed,
  "Fire Edge Going": FireEdgeGoing,
  "Fire Edge Inactive": FireEdgeInactive,
  "Fire Edge Predicted": FireEdgePredicted,
  "Proposed Burn Area": FireProposedBurnArea,
  "Fire Exclusion Area": FireExclusionArea,
  "Burnt Area": FireBurntArea,
  "Previously Burnt Area": FirePreviouslyBurntArea,
  "Emergency Alert": EmergencyAlert,
  "Flooded Area Confirmed": FloodedAreaConfirmed,
  "Flooded Area Predicted": FloodedAreaPredicted,
  "Other Incident Area": OtherIncidentArea,
};

export const FIRE_TRAILS_LEGEND = {
  Strategic: FaftStrategic,
  Tactical: FaftTactical,
  "Track (Other)": FaftTrack,
  "Category 1": FaftSolid,
  "Category 7": FaftDashed,
  "Category 9": FaftDotted,
};

export const FUEL_LOAD_LEGEND = {
  "Unreported Area": "#FFFFFF",
  "0-4 tonnes": "#247400",
  "4-8 tonnes": "#309A00",
  "8-12 tonnes": "#b0e100",
  "12-16 tonnes": "#FFFF00",
  "16-20 tonnes": "#FFA900",
  "20-24 tonnes": "#FF5500",
  "> 24 tonnes": "#A80000",
};

export const FUEL_TYPE_LEGEND = {
  Agriculture: "#FFBEBE",
  Alpine: "#333333",
  "Arid Shrubland": "#FF4747",
  "Dry Sclerophyll Forest": "#275A00",
  Wetland: "#676789",
  "Water/Fuel Absent": "#97DCF2",
  Grassland: "#FFECB0",
  "Grassy Woodland": "#6FA800",
  Heathland: "#884545",
  Plantation: "#CE669A",
  Rainforest: "#A80053",
  "Remnant Vegetation": "#B5D79F",
  "Semi Arid Woodland": "#FF8200",
  Urban: "#CDCDCD",
  "Wet Sclerophyll Forest": "#747FFF",
};

export const HABITABLE_PROPERTIES_LEGEND = {
  "All Habitable Properties": HABITABLE_PROPERTIES_BASE,
  "Swimming Pools": HABITABLE_PROPERTIES_SWIMMING_POOLS,
};

export const HEALTH_FACILITIES_LEGEND = {
  "Hospital General": HealthFacilityHospitalGeneral,
  "Hospital Private": HealthFacilityHospitalPrivate,
  "Indigenous Health Centre": HealthFacilityIndigenousHealthCentre,
  "Disability Support Centre": HealthFacilityDisabilitySupportService,
  Unknown: HealthFacilityUnknown,
};

export const INTERSTATE_INCIDENTS_LEGEND = {
  "Interstate Incident": AlertLevelDefault,
};

export const LAND_USE_LEGEND = {
  "Nature Conservation": "#9666CE",
  "Managed Resource Protection": "#E7BCF5",
  "Other Minimal Use": "#E087DF",
  "Grazing Native Vegetation": "#FFFFE5",
  "Production Native Forestry": "#37A800",
  "Plantation Forests": "#ACFFB5",
  "Grazing Modified Pastures": "#FFD37F",
  Cropping: "#FFFF00",
  "Perennial Horticulture": "#AB877A",
  "Seasonal Horticulture": "#AB87A0",
  "Land in Transition": "#303030",
  "Irrigated Plantation Forests": "#00FFC5",
  "Grazing Irrigated Modified Pastures": "#FFA900",
  "Irrigated Cropping": "#CAB855",
  "Irrigated Perennial Horticulture": "#A86E00",
  "Irrigated Seasonal Horticulture": "#A83700",
  "Irrigated Land in Transition": "#4F4F4F",
  "Intensive Horticulture": "#F57C7C",
  "Intensive Animal Production": "#FFBEBE",
  "Manufacturing and Industrial": "#E64D00",
  "Residential and Farm Infrastructure": "#CECECE",
  Services: "#E60000",
  Utilities: "#C500FF",
  "Transport and Communication": "#B4B4B4",
  Mining: "#247400",
  "Waste Treatment and Disposal": "#47828D",
  Lake: "#97DDF2",
  "Reservoir/Dam": "#BEE8FA",
  River: "#006EFF",
  "Channel/Aqueduct": "#FF00C5",
  "Marsh/Wetland": "#55FF00",
  "Estuary/Coastal Waters": "#97DDF2",
  "Not Entered": "#d7d79f",
};

export const LIVE_TRAFFIC_LEGEND = {
  Accident: LiveTrafficAccident,
  Congestion: LiveTrafficCongestion,
  Construction: LiveTrafficConstruction,
  "Disabled Vehicle": LiveTrafficDisabledVehicle,
  "Lane Restriction": LiveTrafficLaneRestriction,
  "Mass Transit": LiveTrafficMassTransit,
  "Other News": LiveTrafficOtherNews,
  "Planned Event": LiveTrafficPlannedEvent,
  "Road Closure": LiveTrafficRoadClosure,
  "Road Hazard": LiveTrafficRoadHazard,
  Unknown: LiveTrafficUnknown,
  "Weather Event": LiveTrafficWeatherEvent,
};

export const MANUAL_PREDICTION_LEGEND = {
  "Predicted fire edge": PredictedFireEdge,
  "Fire hot spot unconfirmed": FireHotSpotUnconfirmed,
};

export const MOBILE_PHONE_BLACK_SPOTS_LEGEND = {
  "Community Reported Black Spots": BlackspotsReported,
  "High Coverage Area": BlackspotsIneligible,
  Unknown: BlackspotsUnknown,
};

export const NEIGHBOURHOOD_SAFER_PLACES_LEGEND = {
  "Neighbourhood Safer Places": NeighbourhoodSaferPlaces,
};

export const OIL_AND_GAS_PIPELINES_LEGEND = {
  Oil: PipelineOil,
  Gas: PipelineGas,
};

export const POWER_INFRASTRUCTURE_LEGEND = {
  Powerlines: PowerTransmissionLines,
  "Transmission Stations": PowerTransmissionStations,
};

export const RED_MAP_LEGEND = {
  "Potential ember attack": PotentialEmberAttack,
  "Today potential spread area": RedMapToday,
  "Tomorrow potential spread area": RedMapTomorrow,
};

export const RTA_INCIDENTS_LEGEND = {
  "RTA incident": RtaIncident,
};

export const SITE_INFORMATION_LEGEND = {
  "Site Information": SiteInformation,
};

export const STATE_FOREST_PLANTATION_LEGEND = {
  "State Forest Plantation": StateForestPlantation,
};

export const TELEPHONE_EXCHANGE_LEGEND = {
  "Telephone exchange": TelephoneExchange,
};

export const TOTAL_FIRE_BAN_AREAS_LEGEND = {
  Today: TotalFireBanAreasToday,
  Tomorrow: TotalFireBanAreasTomorrow,
};

export const WILDFIRE_HISTORY_LEGEND = {
  "< 2001": "black",
  "2001-02": "#006000",
  "2002-03": "#277600",
  "2003-04": "#4d8c00",
  "2004-05": "#6ea300",
  "2005-06": "#97bd00",
  "2006-07": "#bdd600",
  "2007-08": "#e8f100",
  "2008-09": "#fff300",
  "2009-10": "#ffd100",
  "2010-11": "#ffb400",
  "2011-12": "#ff9000",
  "2012-13": "#ff7200",
  "2013-14": "#ff5100",
  "2014-15": "#ff3700",
  "2015-16": "#ff1f00",
  "2016-17": "#a80000",
  "2017-18": "#742400",
  "2018-19": "#740000",
  "2019-20": "#742400",
  "2020-21": "#541b00",
};
