import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  DEFAULT_TEMPERATURE_CLAMP_C,
  DEFAULT_TIMESTAMP,
} from "../../map/HimawariSurfaceTemperature/constants";

interface HimawariTemperatureContextType {
  temperature: number;
  setTemperature: (temperatureInCelsius: number) => void;
  timestamp: string;
  setTimestamp: (timestamp: string) => void;
  resetTemperatureValues: () => void;
}

const HimawariTemperatureContext = createContext<
  HimawariTemperatureContextType | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};
export const HimawariTemperatureProvider = ({ children }: Props) => {
  const [temperature, setTemperature] = useState(DEFAULT_TEMPERATURE_CLAMP_C);
  const [timestamp, setTimestamp] = useState(DEFAULT_TIMESTAMP);
  const resetTemperatureValues = useCallback(() => {
    setTimestamp(DEFAULT_TIMESTAMP);
    setTemperature(DEFAULT_TEMPERATURE_CLAMP_C);
  }, []);

  const himawariTemperatureProviderValue = useMemo(
    () => ({
      temperature,
      setTemperature,
      timestamp,
      setTimestamp,
      resetTemperatureValues,
    }),
    [
      temperature,
      setTemperature,
      timestamp,
      setTimestamp,
      resetTemperatureValues,
    ],
  );

  return (
    <HimawariTemperatureContext.Provider
      value={himawariTemperatureProviderValue}
    >
      {children}
    </HimawariTemperatureContext.Provider>
  );
};

export const useHimawariTemperature = () => {
  const temperature = useContext(HimawariTemperatureContext);
  if (typeof temperature === "undefined") {
    throw new Error("Must use within a HimawariTemperatureProvider");
  }
  return temperature;
};
