import { useContext } from "react";
import { MapContext, type MapContextValue } from "./MapProvider";

/**
 * Hook to get the Map library instance.
 * Must be called inside a `<MapProvider />` component.
 * Can be used in components which are siblings of the Map component or anywhere that you can't
 * guarantee that it will be used under a Map component.
 *
 * **Does NOT guarantee that the map has been loaded.**
 */
export default function useUnsafeMapContext(): MapContextValue {
  const value = useContext(MapContext);
  if (!value) {
    throw new Error("useUnsafeMapContext must be used within a MapProvider");
  }
  return value;
}
