import { CommonSquare } from "@app/design-system";
import { IconWithLabel, StyledContainer } from "./BaseLayerLegend";

interface CommonIconLegendProps {
  icons: Record<string, string>;
}

const CommonIconLegend = ({ icons }: CommonIconLegendProps) => {
  return (
    <StyledContainer>
      {Object.entries(icons).map(([id, color]) => (
        <IconWithLabel color={color} icon={CommonSquare} id={id} key={id} />
      ))}
    </StyledContainer>
  );
};

export default CommonIconLegend;
