import {
  EmergencyFacilityAmbulanceHeliStation,
  EmergencyFacilityAmbulanceOperationsCentre,
  EmergencyFacilityCommunityFireUnit,
  EmergencyFacilityFireBrigadeStation,
  EmergencyFacilityPoliceServiceStation,
  EmergencyFacilityRFSBridgeStation,
  EmergencyFacilitySESUnitStation,
  EmergencyFacilityStateRescueUnit,
  EmergencyFacilityUnknown,
  StaticIconWrapper,
} from "@app/design-system";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledItem = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  margin: -0.5rem -0.5rem -0.5rem 0;
`;

const StyledText = styled.div`
  ${(p) => p.theme.typography.variants.bodyDefault}
  padding-top: 0.625rem;
`;

interface EmergencyFacilities {
  icon: ReactSVGComponent;
  category: string;
}

const emergencyFacilities: EmergencyFacilities[] = [
  {
    icon: EmergencyFacilityAmbulanceHeliStation,
    category: "Ambulance Heli Station",
  },
  {
    icon: EmergencyFacilityAmbulanceOperationsCentre,
    category: "Ambulance Operations Centre",
  },
  {
    icon: EmergencyFacilityAmbulanceOperationsCentre,
    category: "Ambulance Service Centre",
  },
  {
    icon: EmergencyFacilityAmbulanceOperationsCentre,
    category: "Ambulance Station",
  },
  { icon: EmergencyFacilityCommunityFireUnit, category: "Community Fire Unit" },
  {
    icon: EmergencyFacilityFireBrigadeStation,
    category: "Fire Brigade Station",
  },
  {
    icon: EmergencyFacilityPoliceServiceStation,
    category: "Police Service Station",
  },
  { icon: EmergencyFacilityRFSBridgeStation, category: "RFS Bridge Station" },
  { icon: EmergencyFacilitySESUnitStation, category: "SES Unit Station" },
  { icon: EmergencyFacilityStateRescueUnit, category: "State Rescue Unit" },
  { icon: EmergencyFacilityUnknown, category: "Unknown Facilities" },
];

const EmergencyFacilitiesLegend = () => {
  return (
    <StyledContainer>
      {emergencyFacilities.map(({ icon, category }) => (
        <StyledItem key={category}>
          <StaticIconWrapper icon={icon} size="xxl" />
          <StyledText>{category}</StyledText>
        </StyledItem>
      ))}
    </StyledContainer>
  );
};

export default EmergencyFacilitiesLegend;
