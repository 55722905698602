import styled from "styled-components";

const StyledSoVIAggregationLegend = styled.div`
  display: grid;
  gap: 8px;
`;

const StyledBandGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(13, auto);
`;

const StyledRect = styled.div<{ color: string }>`
  width: 100%;
  height: 16px;
  background: ${(p) => p.color};
`;

const StyledLabels = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledText = styled.div`
  ${(p) => p.theme.typography.variants.caption}
  padding-top: 2px;
  text-align: center;
`;

interface SoviBandsRowDisplay {
  color: string;
}

const soviBandsRow: SoviBandsRowDisplay[] = [
  { color: "#F7E9D7" },
  { color: "#F1DAC3" },
  { color: "#EBCBB3" },
  { color: "#E3BEA4" },
  { color: "#DDAC92" },
  { color: "#D4A083" },
  { color: "#CD9275" },
  { color: "#C68466" },
  { color: "#BF7758" },
  { color: "#B66A4E" },
  { color: "#AE5F42" },
  { color: "#A75335" },
  { color: "#9E4929" },
];

interface SoviLabelsRowDisplay {
  label: string;
}

const soviLabelsRow: SoviLabelsRowDisplay[] = [
  { label: "V. Low" },
  { label: "Low" },
  { label: "Med." },
  { label: "High" },
  { label: "V. High" },
];

const SoVIAggregationLegend = () => {
  return (
    <StyledSoVIAggregationLegend>
      <StyledBandGrid>
        {soviBandsRow.map(({ color }) => (
          <StyledRect key={color} color={color} />
        ))}
      </StyledBandGrid>
      <StyledLabels>
        {soviLabelsRow.map(({ label }) => (
          <StyledText key={label}>{label}</StyledText>
        ))}
      </StyledLabels>
    </StyledSoVIAggregationLegend>
  );
};

export default SoVIAggregationLegend;
