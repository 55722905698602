import {
  ACCOMMODATION_FACILITIES_LEGEND,
  ACT_ESA_RESOURCES_LEGEND,
  AGED_CARE_LEGEND,
  AIRPORTS_AND_HELIPADS_LEGEND,
  BRIGADES_COVERAGE_LEGEND,
  BURNT_AREA_CURRENT_FIRE_SEASON_LEGEND,
  BURNT_AREA_HAZARD_REDUCTION_LEGEND,
  COMMUNICATION_TOWERS_LEGEND,
  EDUCATION_FACILITIES_LEGEND,
  EMERGENCY_ALERTS_LEGEND,
  FIELD_OBS_SIT_REP_UNDER_1_HR_LEGEND,
  FIELD_OBS_SIT_REP_UNDER_3_HRS_LEGEND,
  FIELD_OBS_WEATHER_UNDER_1_HR_LEGEND,
  FIELD_OBS_WEATHER_UNDER_3_HRS_LEGEND,
  FIRE_FEATURES_LEGEND,
  FIRE_TRAILS_LEGEND,
  FUEL_LOAD_LEGEND,
  FUEL_TYPE_LEGEND,
  HABITABLE_PROPERTIES_LEGEND,
  HEALTH_FACILITIES_LEGEND,
  INTERSTATE_INCIDENTS_LEGEND,
  LAND_USE_LEGEND,
  LIVE_TRAFFIC_LEGEND,
  MANUAL_PREDICTION_LEGEND,
  MOBILE_PHONE_BLACK_SPOTS_LEGEND,
  NEIGHBOURHOOD_SAFER_PLACES_LEGEND,
  OIL_AND_GAS_PIPELINES_LEGEND,
  POWER_INFRASTRUCTURE_LEGEND,
  RED_MAP_LEGEND,
  RTA_INCIDENTS_LEGEND,
  SITE_INFORMATION_LEGEND,
  STATE_FOREST_PLANTATION_LEGEND,
  TELEPHONE_EXCHANGE_LEGEND,
  TOTAL_FIRE_BAN_AREAS_LEGEND,
  WILDFIRE_HISTORY_LEGEND,
} from "../map/utils/iconCollections";
import BaseLayerLegend from "./BaseLegends/BaseLayerLegend";
import CommonIconLegend from "./BaseLegends/CommonIconLegend";

export const ACTESAResourcesLegend = () => {
  return <BaseLayerLegend icons={ACT_ESA_RESOURCES_LEGEND} />;
};

export const AccommodationFacilitiesLegend = () => {
  return <BaseLayerLegend icons={ACCOMMODATION_FACILITIES_LEGEND} />;
};

export const AgedCareLegend = () => {
  return <BaseLayerLegend icons={AGED_CARE_LEGEND} />;
};

export const AirportsLegend = () => {
  return <BaseLayerLegend icons={AIRPORTS_AND_HELIPADS_LEGEND} />;
};

export const BrigadesCoverageLegend = () => {
  return <CommonIconLegend icons={BRIGADES_COVERAGE_LEGEND} />;
};

export const BurntAreaCurrentFireSeasonLegend = () => {
  return <BaseLayerLegend icons={BURNT_AREA_CURRENT_FIRE_SEASON_LEGEND} />;
};

export const BurntAreaHazardReductionLegend = () => {
  return <BaseLayerLegend icons={BURNT_AREA_HAZARD_REDUCTION_LEGEND} />;
};

export const CommunicationTowersLegend = () => {
  return <BaseLayerLegend icons={COMMUNICATION_TOWERS_LEGEND} />;
};

export const EducationFacilitiesLegend = () => {
  return <BaseLayerLegend icons={EDUCATION_FACILITIES_LEGEND} />;
};

export const EmergencyAlertsLegend = () => {
  return <BaseLayerLegend icons={EMERGENCY_ALERTS_LEGEND} />;
};

export const FieldObsSitRepUnder1HrLegend = () => {
  return <BaseLayerLegend icons={FIELD_OBS_SIT_REP_UNDER_1_HR_LEGEND} />;
};

export const FieldObsSitRepUnder3HrsLegend = () => {
  return <BaseLayerLegend icons={FIELD_OBS_SIT_REP_UNDER_3_HRS_LEGEND} />;
};

export const FieldObsWeatherUnder1HrLegend = () => {
  return <BaseLayerLegend icons={FIELD_OBS_WEATHER_UNDER_1_HR_LEGEND} />;
};

export const FieldObsWeatherUnder3HrsLegend = () => {
  return <BaseLayerLegend icons={FIELD_OBS_WEATHER_UNDER_3_HRS_LEGEND} />;
};

export const FireFeaturesLegend = () => {
  return <BaseLayerLegend icons={FIRE_FEATURES_LEGEND} />;
};

export const FireTrailsLegend = () => {
  return <BaseLayerLegend icons={FIRE_TRAILS_LEGEND} />;
};

export const FuelLoadLegend = () => {
  return <CommonIconLegend icons={FUEL_LOAD_LEGEND} />;
};

export const FuelTypeLegend = () => {
  return <CommonIconLegend icons={FUEL_TYPE_LEGEND} />;
};

export const HabitablePropertiesLegend = () => {
  return <CommonIconLegend icons={HABITABLE_PROPERTIES_LEGEND} />;
};

export const HealthFacilitiesLegend = () => {
  return <BaseLayerLegend icons={HEALTH_FACILITIES_LEGEND} />;
};

export const InterstateIncidentsLegend = () => {
  return <BaseLayerLegend icons={INTERSTATE_INCIDENTS_LEGEND} />;
};

export const LandUseLegend = () => {
  return <CommonIconLegend icons={LAND_USE_LEGEND} />;
};

export const LiveTrafficLegend = () => {
  return <BaseLayerLegend icons={LIVE_TRAFFIC_LEGEND} />;
};

export const ManualPredictionLegend = () => {
  return <BaseLayerLegend icons={MANUAL_PREDICTION_LEGEND} />;
};

export const MobileBlackspotLegend = () => {
  return <BaseLayerLegend icons={MOBILE_PHONE_BLACK_SPOTS_LEGEND} />;
};

export const NeighbourhoodSaferPlacesLegend = () => {
  return <BaseLayerLegend icons={NEIGHBOURHOOD_SAFER_PLACES_LEGEND} />;
};

export const OilAndGasPipelinesLegend = () => {
  return <BaseLayerLegend icons={OIL_AND_GAS_PIPELINES_LEGEND} />;
};

export const PowerInfrastructureLegend = () => {
  return <BaseLayerLegend icons={POWER_INFRASTRUCTURE_LEGEND} />;
};

export const RedMapLegend = () => {
  return <BaseLayerLegend icons={RED_MAP_LEGEND} />;
};

export const RtaIncidentsLegend = () => {
  return <BaseLayerLegend icons={RTA_INCIDENTS_LEGEND} />;
};

export const SiteInformationLegend = () => {
  return <BaseLayerLegend icons={SITE_INFORMATION_LEGEND} />;
};

export const StateForestPlantationLegend = () => {
  return <BaseLayerLegend icons={STATE_FOREST_PLANTATION_LEGEND} />;
};

export const TelephoneExchangeLegend = () => {
  return <BaseLayerLegend icons={TELEPHONE_EXCHANGE_LEGEND} />;
};

export const TotalFireBanAreasLegend = () => {
  return <BaseLayerLegend icons={TOTAL_FIRE_BAN_AREAS_LEGEND} />;
};

export const WildfireHistoryLegend = () => {
  return <CommonIconLegend icons={WILDFIRE_HISTORY_LEGEND} />;
};
