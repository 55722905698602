import {
  type FormControlOption,
  Field,
  Select,
  Slider,
  showToast,
} from "@app/design-system";
import isArray from "lodash/isArray";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import formatDate from "../../../utils/formatDate/formatDate";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import {
  COLOR_SCALE,
  DEFAULT_TEMPERATURE_CLAMP_C,
  DEFAULT_TIMESTAMP,
} from "../../map/HimawariSurfaceTemperature/constants";
import {
  celsiusToKelvin,
  kelvinToCelsius,
  normalizeTemperature,
} from "../../map/HimawariSurfaceTemperature/utils";
import { useHimawariTemperature } from "../../util/HimawariTemperatureProvider/HimawariTemperatureProvider";

const MIN_ALLOWABLE_KELVIN = 273.15;
const MAX_ALLOWABLE_KELVIN = 353.15;

const MIN_C = Math.floor(kelvinToCelsius(MIN_ALLOWABLE_KELVIN));
const MAX_C = Math.floor(kelvinToCelsius(MAX_ALLOWABLE_KELVIN));
const RANGE_BUFFER = 1;

const StyledHimawariSurfaceTemperatureLegend = styled.div`
  display: grid;
  gap: 16px;
`;

const StyledTemperatureControls = styled.div`
  display: grid;
  gap: 16px;
`;

const StyledTemperature = styled.output`
  font-variant-numeric: tabular-nums;
`;

const StyledColourGradient = styled.div`
  --color-1: ${COLOR_SCALE[0]};
  --color-2: ${COLOR_SCALE[1]};
  --color-3: ${COLOR_SCALE[2]};
  --color-4: ${COLOR_SCALE[3]};
  --position-1: 0%;
  --position-2: 50%;
  --position-3: 50%;
  --position-4: 100%;
  width: 100%;
  border-radius: 10px;
  height: 20px;
  background: linear-gradient(
    90deg,
    var(--color-1) var(--position-1),
    var(--color-2) var(--position-2),
    var(--color-3) var(--position-3),
    var(--color-4) var(--position-4)
  );
`;

const fetchAvailableHimawariTimestamps = async (
  accessToken: string,
): Promise<Array<string>> => {
  const query = new URLSearchParams({
    service: "WMS",
    version: "1.1.1",
    request: "GetCapabilities",
  }).toString();
  let response;
  try {
    response = await fetch(
      `${getMapServerProxyBasepath()}/geoserver/aig/wms?${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  } catch (e) {
    throw new Error("Failed to reach Himawari data server");
  }
  if (!response.ok) {
    throw new Error("Failed to fetch Himawari data");
  }
  try {
    const xml = await response.text();
    const parser = new DOMParser();
    const dom = parser.parseFromString(xml, "application/xml");

    const timestamps =
      dom.querySelector("Extent[name='time']")?.innerHTML.split(",") ?? [];

    const sortedTimestamps = timestamps.sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime(),
    );
    return ["current", ...sortedTimestamps];
  } catch (error) {
    throw new Error("Failed to parse Himawari data");
  }
};

const computeGradientPercentage = (temperature: number) => {
  const temperatureInKelvin = celsiusToKelvin(temperature);
  const normalizedTemperature = normalizeTemperature(temperatureInKelvin);
  return normalizedTemperature * 100;
};

const mapTimestampsToSelectOptions = (timestamps: Array<string>) => {
  return timestamps.map((timestamp) => ({
    value: timestamp,
    label:
      timestamp === DEFAULT_TIMESTAMP
        ? "Current time"
        : formatDate(new Date(timestamp), "fullTimeAndDate"),
  }));
};

const HimawariSurfaceTemperatureLegend = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { temperature, setTemperature, setTimestamp, timestamp } =
    useHimawariTemperature();
  const [timestampOptions, setTimestampOptions] = useState<FormControlOption[]>(
    [],
  );

  const accessToken = useAuthAccessToken();

  useEffect(() => {
    if (!accessToken) return;

    const loadTimestamps = async () => {
      setIsLoading(true);
      try {
        const timestamps = await fetchAvailableHimawariTimestamps(accessToken);
        const optionsWithLabel = mapTimestampsToSelectOptions(timestamps);
        setTimestampOptions(optionsWithLabel);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error instanceof Error) {
          showToast({
            title: error.message,
            variant: "error",
          });
        }
      }
    };

    void loadTimestamps();
  }, [accessToken]);

  const gradientPercentage = computeGradientPercentage(temperature);

  return (
    <StyledHimawariSurfaceTemperatureLegend>
      <Select
        id="timestamp"
        isLoading={isLoading}
        label="Timestamp"
        options={timestampOptions}
        onChange={(option) => {
          if (!option) return;
          setTimestamp(option.value);
          setTemperature(DEFAULT_TEMPERATURE_CLAMP_C);
        }}
        value={timestampOptions.find((ts) => ts.value === timestamp)}
      />
      <Field
        htmlFor="himawariSurfaceTemperatureSlider"
        label={<>Temperature clamp modifier</>}
      >
        <StyledTemperatureControls>
          <Slider
            id="himawariSurfaceTemperatureSlider"
            formatTooltip={(value) => `${value}°C`}
            minValue={MIN_C + RANGE_BUFFER}
            maxValue={MAX_C - RANGE_BUFFER}
            onChange={(value) => {
              if (!isArray(value)) {
                setTemperature(value);
              }
            }}
            step={1}
            value={temperature}
            tooltip
          >
            <option value={MIN_C}>{MIN_C}°C</option>
            <option value={MAX_C}>{MAX_C}°C</option>
          </Slider>
        </StyledTemperatureControls>
      </Field>
      <Field
        label={
          <>
            Clamped temperature:{" "}
            <StyledTemperature htmlFor="himawariSurfaceTemperatureSlider">
              {temperature}°C
            </StyledTemperature>
          </>
        }
      >
        <StyledColourGradient
          style={{
            ["--position-2" as string]: `${gradientPercentage}%`,
            ["--position-3" as string]: `${gradientPercentage}%`,
          }}
        />
      </Field>
    </StyledHimawariSurfaceTemperatureLegend>
  );
};

export default HimawariSurfaceTemperatureLegend;
