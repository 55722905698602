import { Field, FieldGroup, Spinner } from "@app/design-system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import type { MapImage } from "../utils/loadImage";
import {
  getFireMapperGISUrls,
  getFireMapperMapIcons,
  type FireMapperFeatureServerIconJson,
  type fireMapperLayerNames,
} from "./utils";

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const StyledText = styled.div`
  ${(p) => p.theme.typography.variants.footnote}
`;

const Styled4ColGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto minmax(0, 1fr);
  align-items: center;
  gap: 8px;
`;

const legendLayers = [
  "Labels",
  "Points",
  "Photos",
  "PDFs",
] as const satisfies readonly (typeof fireMapperLayerNames)[number][];

interface LoadSymbologyParams {
  accessToken: string | undefined;
  layer: (typeof legendLayers)[number];
  signal: AbortSignal;
}

const loadSymbologyList = (params: LoadSymbologyParams) => {
  const { symbologyUrl } = getFireMapperGISUrls(params.layer, "3Hrs");

  return fetch(symbologyUrl, {
    headers: symbologyUrl.includes("shared-athena")
      ? { Authorization: `Bearer ${params.accessToken}` }
      : undefined,
    signal: params.signal,
  })
    .then((response) => {
      params.signal.throwIfAborted();
      return response.json() as Promise<FireMapperFeatureServerIconJson>;
    })
    .then((data) => {
      params.signal.throwIfAborted();
      return Promise.all([
        {
          imageId: "Default Icon",
          src: `/icons/firemapper_${params.layer.toLowerCase()}_fallback.png`,
        } as MapImage,
        ...getFireMapperMapIcons(data),
      ]);
    });
};

const FireMapperLegend = () => {
  const accessToken = useAuthAccessToken();
  const [symbology, setSymbology] =
    useState<Record<LoadSymbologyParams["layer"], MapImage[]>>();

  useEffect(() => {
    const controller = new AbortController();

    void Promise.all(
      legendLayers.map((layer) =>
        loadSymbologyList({ accessToken, layer, signal: controller.signal }),
      ),
    ).then((loadedSymbology) =>
      setSymbology(
        Object.fromEntries(
          legendLayers.map((layer, index) => [layer, loadedSymbology[index]]),
        ) as unknown as Record<(typeof legendLayers)[number], MapImage[]>,
      ),
    );

    return () => controller.abort();
  }, [accessToken]);

  return (
    <FieldGroup>
      {legendLayers.map((layer) => (
        <Field key={layer} label={layer}>
          {symbology ? (
            <Styled4ColGrid>
              {symbology[layer as LoadSymbologyParams["layer"]].map(
                ({ src, imageId }) => (
                  <React.Fragment key={`${layer}-${imageId}`}>
                    <StyledImage height="20" src={src} alt="" aria-hidden />
                    <StyledText>{imageId}</StyledText>
                  </React.Fragment>
                ),
              )}
            </Styled4ColGrid>
          ) : (
            <Spinner />
          )}
        </Field>
      ))}
    </FieldGroup>
  );
};

export default FireMapperLegend;
