import { Skeleton, Text } from "@app/design-system";
import type { LayerHintComponentProps } from "../../../config/layers/layers";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import { useGetAerialImageryMeta } from "./useGetAerialImageryMeta";
import { aerialImageryLayers, isAerialImageryLayerId } from "./utils";

const AerialImageryLayerHint = ({
  isVisible,
  layerId,
}: LayerHintComponentProps) => {
  const isValidLayerId = isAerialImageryLayerId(layerId);

  if (!isValidLayerId) {
    throw new Error(`Unknown aerial imagery layer ID: ${layerId}`);
  }

  const aerialImageryLayer = aerialImageryLayers[layerId];

  const { data, isPending, isError } = useGetAerialImageryMeta({
    enabled: isVisible,
    aerialImageryLayer,
  });

  if (isPending) {
    return (
      <Text size="footnote" variant="weak">
        <Skeleton width={120} />
      </Text>
    );
  }

  if (isError || !data) {
    return (
      <Text size="footnote" variant="weak">
        Unavailable
      </Text>
    );
  }

  const layerUpdateTime = data.lastUpdated;

  if (!layerUpdateTime) {
    return (
      <Text size="footnote" variant="weak">
        No imagery
      </Text>
    );
  }

  return (
    <Text size="footnote" variant="weak">
      Latest {getFormattedDateAndTime(new Date(layerUpdateTime))}
    </Text>
  );
};

export default AerialImageryLayerHint;
