import type { BBox } from "geojson";

export const MIN_KELVIN = 95;
export const MAX_KELVIN = 400;
export const CANVAS_WIDTH = 1024;
export const CANVAS_HEIGHT = 768;
export const AUSTRALIA_BBOX_4326: BBox = [
  113.131886, -43.780886, 155.319386, -10.501146,
];
export const AUSTRALIA_BBOX_3857: BBox = [
  12593783.8895, -5431596.1207, 17290074.9074, -1175582.3858,
];
export const COLOR_SCALE = ["#f1f1f1", "#000", "#ffdd00", "#ff0000"];
export const NULL_VALUE = -32767;
export const DEFAULT_TEMPERATURE_CLAMP_C = 47;
export const DEFAULT_TIMESTAMP = "current";
