import { Field, FieldGroup } from "@app/design-system";
import styled from "styled-components";

const StyledRange = styled.div`
  display: grid;
  padding: 0.25rem 0 0;
  grid-template-columns: repeat(5, auto);
  row-gap: 0.5rem;
`;

const StyledRect = styled.div<{ color: string }>`
  width: 100%;
  height: 1rem;
  background: ${(p) => p.color};
`;

const StyledText = styled.div`
  ${(p) => p.theme.typography.variants.caption}
  padding-top: 0.125rem;
  text-align: center;
`;

export interface ColorRange {
  color: string;
  range: [number, number] | string;
}

export interface ColorRangeCollection {
  label: string;
  ranges: ColorRange[];
}

interface ColorRangeLegendProps {
  ranges: ColorRangeCollection[];
}

const ColorRangeLegend = ({ ranges }: ColorRangeLegendProps) => {
  return (
    <FieldGroup>
      {ranges.map(({ label, ranges: colorRanges }) => (
        <Field key={label} label={label}>
          <StyledRange>
            {colorRanges.map(({ color, range }) => {
              const isRangeArray = Array.isArray(range);

              return (
                <div key={color}>
                  <StyledRect color={color} />
                  <StyledText>
                    {isRangeArray ? `${range[0]} - ${range[1]}` : range}
                  </StyledText>
                </div>
              );
            })}
          </StyledRange>
        </Field>
      ))}
    </FieldGroup>
  );
};

export default ColorRangeLegend;
