import React, { createContext, useContext } from "react";
import type { UseActiveLayersReturn } from "./useActiveLayers";

type ActiveLayersContextValue = UseActiveLayersReturn;

const ActiveLayersContext = createContext<ActiveLayersContextValue | undefined>(
  undefined,
);

type ActiveLayersProviderProps = UseActiveLayersReturn & {
  children?: React.ReactNode;
};

export const ActiveLayersProvider = ({
  children,
  ...value
}: ActiveLayersProviderProps) => {
  return (
    <ActiveLayersContext.Provider value={value}>
      {children}
    </ActiveLayersContext.Provider>
  );
};

export const useActiveLayersContext = () => {
  const value = useContext(ActiveLayersContext);

  if (!value) {
    throw new Error(
      "useActiveLayersContext must be used inside an ActiveLayersProvider",
    );
  }

  return value;
};
