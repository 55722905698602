import { FIRE_CONDITIONS_LAYERS } from "../../map/FireConditions/fireConditionsUrls";

export const getQuery = (id: keyof typeof FIRE_CONDITIONS_LAYERS) => {
  return new URLSearchParams({
    service: "WMS",
    version: "1.1.0",
    request: "GetLegendGraphic",
    format: "application/json",
    layer: FIRE_CONDITIONS_LAYERS[id],
  });
};

interface GeoserverLegendJson {
  Legend: Array<{
    rules: Array<{
      symbolizers: Array<{
        Raster: {
          colormap: {
            entries: Array<{
              color: string;
              label: string;
              quantity?: string;
            }>;
          };
          type: string;
        };
        opacity: number;
      }>;
    }>;
  }>;
}

const assertLegendData = (data: unknown): data is GeoserverLegendJson => {
  const entries =
    // @ts-expect-error - runtime checking the data exists in the right format
    data?.Legend?.[0]?.rules?.[0]?.symbolizers?.[0]?.Raster?.colormap?.entries;
  if (!Array.isArray(entries)) {
    return false;
  }

  return entries.every((entry: { color: unknown; label: unknown }) => {
    return typeof entry.color === "string" && typeof entry.label === "string";
  });
};
/**
 * Raster symbolizers contain a colormap with an array of entries,
 * each entry contains a label, quantity and color element.
 * @see https://docs.geoserver.org/stable/en/user/services/wms/get_legend_graphic/index.html#json-output-format
 * @param json
 */
export const parseLegendData = (json: unknown) => {
  if (!assertLegendData(json)) return {};
  return json.Legend[0].rules[0].symbolizers[0].Raster.colormap.entries.reduce(
    (obj: Record<string, string>, entry: { color: string; label: string }) => {
      const { color, label } = entry;
      // eslint-disable-next-line no-param-reassign
      obj[label] = color;
      return obj;
    },
    {},
  );
};
export const isFireConditionLayerId = (
  id: string | undefined,
): id is keyof typeof FIRE_CONDITIONS_LAYERS => {
  return !!id && Object.keys(FIRE_CONDITIONS_LAYERS).includes(id);
};
