import { Field, FieldGroup } from "@app/design-system";
import styled from "styled-components";

const StyledText = styled.div`
  ${(p) => p.theme.typography.variants.caption}
  padding-top: 2px;
  text-align: center;
`;

const StyledHoursSinceHotspotGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
`;

const StyledConfidenceLevelDisplayGrid = styled.div`
  display: grid;
  gap: 42px;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: baseline;
`;

const StyledRect = styled.div<{ color: string }>`
  width: 100%;
  height: 16px;
  background: ${(p) => p.color};
`;

const StyledCircle = styled.div<{ size: string }>`
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  border-radius: 100%;
  background: black;
`;

interface HoursSinceHotspotDisplay {
  color: string;
  label: string;
}

const hoursSinceHotspot: HoursSinceHotspotDisplay[] = [
  { color: "#AF0126", label: "0 - 2" },
  { color: "#DF3822", label: "2 - 6" },
  { color: "#EB853D", label: "6 - 24" },
  { color: "#ECC15C", label: "24 - 48" },
  { color: "#EDF0AC", label: "48 - 72" },
];

interface ConfidenceLevelDisplay {
  label: string;
  size: string;
}

const confidenceLevel: ConfidenceLevelDisplay[] = [
  { label: "< 30%", size: "9px" },
  { label: "30 - 80%", size: "17px" },
  { label: "80 - 100%", size: "22px" },
];

const DEAHotspotsLegend = () => {
  return (
    <FieldGroup>
      <Field label="Hours since hotspot">
        <StyledHoursSinceHotspotGrid>
          {hoursSinceHotspot.map(({ color, label }) => (
            <div key={label}>
              <StyledRect color={color} />
              <StyledText>{label}</StyledText>
            </div>
          ))}
        </StyledHoursSinceHotspotGrid>
      </Field>
      <Field label="Confidence level">
        <StyledConfidenceLevelDisplayGrid>
          {confidenceLevel.map(({ label, size }) => (
            <div key={label}>
              <StyledCircle size={size} />
              <StyledText>{label}</StyledText>
            </div>
          ))}
        </StyledConfidenceLevelDisplayGrid>
      </Field>
    </FieldGroup>
  );
};

export default DEAHotspotsLegend;
