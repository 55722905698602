import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { FIRE_WEATHER_URL } from "../../../config/general";
import type { Legend } from "../../../config/layers/layers";
import CommonIconLegend from "../BaseLegends/CommonIconLegend";
import { getQuery, isFireConditionLayerId, parseLegendData } from "./utils";

const getLegendData = (id: string | undefined) => {
  if (!isFireConditionLayerId(id)) {
    throw new Error("Unrecognised layer ID");
  }

  return axios
    .get(`${FIRE_WEATHER_URL}?${getQuery(id)}`)
    .then((response) => response.data);
};

const useGetLegendData = (id: string | undefined) => {
  const query = useQuery({
    enabled: !!id,
    queryKey: ["getGeoserverRasterLegend", id],
    queryFn: () => getLegendData(id),
    staleTime: Infinity,
  });

  return query;
};

const GeoserverRasterLegend: Legend["component"] = (props) => {
  const { id } = props;

  const { data } = useGetLegendData(id);

  if (!data) return null;

  const legendData = parseLegendData(data);

  return <CommonIconLegend icons={legendData} />;
};

export default GeoserverRasterLegend;
